import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Dashboard from '../../images/homepage_dashboard.png';
import { navigate } from 'gatsby';
import { Container } from '../global';
import Helmet from 'react-helmet';

const Header = (props) => {
  const [email, setEmail] = useState(null);
  const [hostRoute, setHostRoute] = useState('');

  useEffect(() => {
    if (typeof window !== `undefined`) setHostRoute(window.location.host);
  }, []);

  const loginRoute = (host) => {
    if (host.includes('dealmap')) {
      return 'https://app.dealmap.com';
    } else if (host.includes('dinghydeal')) {
      return 'https://app.dinghydeal.com';
    } else if (host.includes('thelonehipster')) {
      return 'https://app.thelonehipster.com';
    } else if (host.includes('splatmapper')) {
      return 'https://app.splatmapper.com';
    } else if (host.includes('localhost')) {
      return 'localhost:4000';
    } else {
      return '/';
    }
  };
  return (
    <HeaderWrapper id="top">
      <Helmet>
        <script>
          {` 
        function createFcn(nm){(window.freshsales)[nm]=function(){(window.freshsales).push([nm].concat(Array.prototype.slice.call(arguments,0)))}; } (function(url,appToken,formCapture){window.freshsales=window.freshsales||[];if(window.freshsales.length==0){list='init identify trackPageView trackEvent set'.split(' ');for(var i=0;i<list.length;i++){var nm=list[i];createFcn(nm);}var t=document.createElement('script');t.async=1;t.src='https://assets.freshsales.io/assets/analytics.js';var ft=document.getElementsByTagName('script')[0];ft.parentNode.insertBefore(t,ft);freshsales.init('https://dealmap.freshsales.io','a11809247e740c58d7c72cede7f87a9b3288f0c1fa0cfaa11cc99a85f349c9a2',true);}})();
        `}
        </script>
        <title>Deal Map | Accounting Made Simple</title>
        <meta
          name="description"
          content="We're building next generation deal management tools. Sign up for early access."
        ></meta>
      </Helmet>
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>Smart deal management</Subtitle>
            <h1>Accounting made simple</h1>
            <h2>
              We're building next generation deal management tools. <br /> <br /> Try it risk-free
              today!
            </h2>
            <HeaderButton href={`${loginRoute(hostRoute)}/initial-signup`}>
              SIGN UP NOW
            </HeaderButton>
            <FormSubtitle>
              Already have an account?{' '}
              <a href={`${loginRoute(hostRoute)}/direct-login`} style={{ textDecoration: 'none' }}>
                Log in
              </a>
            </FormSubtitle>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage src={Dashboard} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 120px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding-bottom: 40px;
  }
`;
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`;

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`;

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 40px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`;

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`;

const FormSubtitle = styled.span`
  ${(props) => props.theme.font_size.xxsmall}
`;

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`;

const HeaderButton = styled.a`
  font-weight: 500;
  font-size: 20px;
  margin: 0 auto;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  text-align: center;
  width: 240px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: rgb(255, 147, 79);
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  text-decoration: none;
  line-height: 60px;
  margin-bottom: 2rem;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`;

const StyledImage = styled.img`
  width: 500px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`;
