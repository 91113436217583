import React from 'react';
import styled from 'styled-components';
import Lock from '../../images/lock.png';
import Integration from '../../images/integration.png';
import Analysis from '../../images/analysis.png';
import Receipt from '../../images/receipt.png';

import { Section, Container } from '../global';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Smart Deal Management</SectionTitle>
      <FeaturesGrid>
        <FeatureItem to="/features#deal-management">
          <img src={Receipt} alt="invoicing" />
          <FeatureTitle>Deal Management</FeatureTitle>
          <FeatureText>
            Deal Map is accounting software designed for real estate brokers and agents. It makes
            tracking and organizing a sale or rental agreement easy.
          </FeatureText>
        </FeatureItem>
        <FeatureItem to="/features#accounting">
          <img src={Analysis} alt="reporting" />
          <FeatureTitle>Accounting</FeatureTitle>
          <FeatureText>
            Most accounting systems have a hard time aggregating all the transactions to account for
            the purchase and sale of a single item. Deal Map makes it simple.
          </FeatureText>
        </FeatureItem>
        <FeatureItem to="/features#integration">
          <img src={Integration} alt="integration" />
          <FeatureTitle>Integration</FeatureTitle>
          <FeatureText>
            Deal Map can be used as a stand-alone product. We recommend integrating with Xero to
            unlock additional features and extend functionality.
          </FeatureText>
        </FeatureItem>
        <FeatureItem to="/features#security">
          <img src={Lock} alt="security" />
          <FeatureTitle>Security</FeatureTitle>
          <FeatureText>
            We use best practices at every turn and aim to prioritize user privacy and security when
            developing our product. Our app uses industry best practices and security providers.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
);

export default Features;

const StyledContainer = styled(Container)``;

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 60px;
  text-align: center;
`;

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`;

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`;

const FeatureItem = styled(AnchorLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.color.primary};
  text-decoration: none;
`;

const FeatureTitle = styled.p`
  font-size: 28px;
  font-family: 'HK Grotesk Bold';
  margin-block-start: 1.33em;
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`;

const FeatureText = styled.p`
  text-align: center;
`;
